import { library } from "@fortawesome/fontawesome-svg-core";
// 可用图标集合: https://fontawesome.com/v6.1.1/icons?d=gallery&p=2&s=brands,regular,solid&m=free
// 用到的图片需要手动引用并添加到 library
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

import { faCircleCheck as faRegularCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faMessage as faRegularMessage } from "@fortawesome/free-regular-svg-icons";

import { faMarkdown } from "@fortawesome/free-brands-svg-icons";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons";
import { faAppStoreIos } from "@fortawesome/free-brands-svg-icons";
import { faJs } from "@fortawesome/free-brands-svg-icons";

library.add(
  faBug,
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faBell,
  faCalendarAlt,
  faCode,
  faDatabase,
  faPlay,
  faCube,
  faHashtag,
  faCodeBranch,
  faExpandAlt,
  faCompressAlt,
  faPencilAlt,
  faFolder,
  faFolderOpen,
  faListCheck,
  faArrowUp,
  faArrowDown,
  faCopy,
  faTrashCan,
  faPlus,
  faBook,
  faFileCircleCheck,
  faWandMagicSparkles,
  faRotate,
  faQuestion,

  faRegularCircleCheck,
  faRegularMessage,

  faMarkdown,
  faSlackHash,
  faAppStoreIos,
  faJs,
  faCrosshairs
);
