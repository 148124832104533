<template>
  <div class="chat-room-settings">
    <el-dialog title="Chatbot Settings" :visible.sync="visible" :before-close="handleClose" :fullscreen="true">
      <el-form :model="form" label-width="auto" label-position="right" size="small">
        <el-form-item label="Chatbot Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Context Messages">
          <el-input type="number" v-model="form.context_messages_count"></el-input>
        </el-form-item>
        <el-form-item label="LLVM Model">
          <el-select v-model="form.model">
            <el-option label="GPT-3" value="gpt-3.5-turbo"></el-option>
          </el-select>
        </el-form-item>
        <!-- gpt-3.5-turbo 模型设置 -->
        <el-form-item label="temperature">
          <el-slider v-model="form.model_properties.temperature" :min="0" :max="2" :step="0.01"></el-slider>
        </el-form-item>
        <el-form-item label="presence_penalty">
          <el-slider v-model="form.model_properties.presence_penalty" :min="-2" :max="2" :step="0.01"></el-slider>
        </el-form-item>
        <el-form-item label="frequency_penalty">
          <el-slider v-model="form.model_properties.frequency_penalty" :min="-2" :max="2" :step="0.01"></el-slider>
        </el-form-item>

        <el-divider content-position="left">Prompts</el-divider>
        <el-form-item label="System Prompt">
          <el-input v-model="prompt_system.content"></el-input>
        </el-form-item>
        <el-form-item v-for="(prompt, index) in prompt_others" :label="prompt.role" :key="index" :prop="prompt.content">
          <el-input class="prompt-content" v-model="prompt.content"></el-input>
          <el-button class="el-icon-delete" type="danger" @click.prevent="removePrompt(prompt)"></el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="addPrompt">Add Prompt</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">Cancel</el-button>
        <el-button type="primary" @click="handleSave" size="small">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import _ from "lodash";

  var defaultForm = {
    name: "assitant",
    avatar: "/avatars/elon-musk.png",
    model: "gpt-3.5-turbo",
    model_properties: { top_p: 1, temperature: 1, presence_penalty: 0, frequency_penalty: 0 },
    prompts: [{ role: "system", content: "You are a helpful assistant." }],
    context_messages_count: 0,
  };

  // var defaultModelProperties = {
  //   temperature: 0.7,
  // };

  export default {
    name: "ChatRoomSettings",
    props: {},
    data() {
      return {
        visible: false,
        form: undefined,
        prompt_system: {
          role: "system",
          content: "",
        },
        prompt_others: [],
      };
    },
    methods: {
      async Show(roomId) {
        // 新增房间
        if (roomId == undefined) {
          this.loadForm(_.cloneDeep(defaultForm));
        } else {
          // 修改房间
          let chatbotId = _.split(roomId, "_")[1];
          var chatbot = await this.$API.getChatbot(chatbotId);
          this.loadForm(chatbot);
        }
        this.visible = true;
      },
      loadForm(form) {
        this.form = form;
        this.prompt_system = form.prompts[0];
        this.prompt_others = form.prompts.slice(1);
      },
      addPrompt() {
        var role = "user";
        let last_prompt = _.last(this.prompt_others);
        if (last_prompt && last_prompt.role == "user") {
          role = "assitant";
        }
        this.prompt_others.push({
          role: role,
          content: "",
        });
      },
      removePrompt(promp) {
        this.prompt_others = _.filter(this.prompt_others, (p) => p != promp);
      },
      handleClose() {
        this.visible = false;
      },
      async handleSave() {
        this.form.prompts = [this.prompt_system, ...this.prompt_others];
        if (this.form.id) {
          await this.$API.updateChatbot(this.form);
        } else {
          await this.$API.createChatbot(this.form);
        }
        this.visible = false;
        this.$emit("done");
      },
    },
    created() {
      this.form = _.cloneDeep(defaultForm);
    },
  };
</script>

<style lang="scss" scoped>
  .chat-room-settings {
    ::v-deep .prompt-content {
      width: calc(100% - 50px);
    }
  }
</style>
