import moment from "moment";

moment.locale("zh-cn");

function friendlyTime(t) {
  return moment(t).fromNow();
}

function durationFrom(to, from) {
  if (!to) {
    return "-";
  }
  var duration = moment.duration(new Date(to) - new Date(from));
  return duration.humanize();
}

function RegistorFilters(vm) {
  vm.filter("friendlyTime", friendlyTime);
  vm.filter("durationFrom", durationFrom);
}

export default RegistorFilters;
