import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";

axios.interceptors.request.use(
  config => {
    var accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    var env = localStorage.getItem("currentEnv");
    if (env == null) {
      env = "hk";
    }

    config.params = {
      _env: env,
      ...config.params,
    };
    return config;
  },
  err => {
    Message.error({ message: "请求超时!" });
    return Promise.resolve(err);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.data && response.data.is_succ != undefined && !response.data.is_succ) {
      // 过滤固定错误码，由业务处理
      if (response.data.data.error_code == 23013) {
        return response;
      }
      // 登录页面不提示 未登录
      if (response.data.data.error_code == 12001) {
        if (router.currentRoute.path != "/auth/login") {
          router.push("/auth/login");
        }
      } else {
        Message.error({ message: response.data.data.error_message });
      }
      throw response.data.data;
    }
    return response;
  },
  error => {
    if (error.response.status != 200) {
      console.log(error);
      Message.error({ message: "接口异常", duration: 1000 });
    }
    return Promise.resolve(error);
  }
);
