import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
// import store from "./store";
import "./axios-setup";

// ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./font-awesome.js";
Vue.component("font-awesome-icon", FontAwesomeIcon);

// vGoogleLogin
import vGoogleLogin from "vue-google-login-directive";
Vue.use(vGoogleLogin);

// 注册 filters
import RegistorFilters from "./filters.js";
RegistorFilters(Vue);

// 注册 API
import API from "./API.js";
Vue.prototype.$API = API;

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
