<template>
  <div class="chat-room">
    <vue-advanced-chat
      ref="vac"
      height="100vh"
      :current-user-id="'user_' + currentUserId"
      :rooms="JSON.stringify(rooms)"
      :rooms-loaded="roomLoaded"
      :room-info-enabled="true"
      :room-actions="JSON.stringify(roomActions)"
      :messages="JSON.stringify(messages)"
      :messages-loaded="JSON.stringify(messagesLoaded)"
      message-actions="[]"
      :single-room="false"
      show-emojis="false"
      show-reaction-emojis="false"
      show-audio="false"
      show-search="true"
      show-add-room="true"
      show-files="false"
      :show-send-icon="false"
      :menu-actions="JSON.stringify(menuActions)"
      @fetch-messages="fetchMessages($event.detail[0])"
      @send-message="sendMessage($event.detail[0])"
      @room-info="showRoomInfo($event.detail[0])"
      @add-room="addRoomm"
      @room-action-handler="roomActionHandler($event.detail[0])"
    >
    </vue-advanced-chat>
    <div id="suggestions">
      <el-button
        v-for="suggestion in suggestions"
        :key="suggestion"
        type="success"
        plain
        round
        @click="handleSuggestionClick(suggestion)"
      >
        {{ suggestion }}
      </el-button>
    </div>
    <ChatRoomSettings ref="chatroomSettings" @done="handleSave"></ChatRoomSettings>
  </div>
</template>

<script>
  import _ from "lodash";
  import { io } from "socket.io-client";
  import { register } from "vue-advanced-chat";
  import Model from "@/Model";
  import ChatRoomSettings from "./ChatRoomSettings";
  import settings from "@/settings";
  register();

  export default {
    name: "ChatRoom",
    components: {
      ChatRoomSettings,
    },
    props: {
      msg: String,
    },
    data() {
      return {
        currentUserId: 1,
        currentRoomId: undefined,
        roomLoaded: false,
        messagesLoaded: false,
        rooms: [],
        messages: [],
        suggestions: [],
        roomActions: [{ name: "delete", title: "Delete" }],
        menuActions: [{ name: "setting", title: "Setting" }],
      };
    },
    watch: {
      currentRoomId() {
        console.log("currentRoomId", this.currentRoomId);
        this.ws.emit("join", {
          user_id: this.currentUserId,
          room_id: this.currentRoomId,
        });
      },
    },
    methods: {
      async fetchRooms() {
        let resp = await this.$API.getRooms();
        this.rooms = Model.Room.CreateList(resp);
        this.roomLoaded = true;
      },
      async fetchMessages({ room, options = {} }) {
        var messages = [];
        this.currentRoomId = room.roomId;
        this.messagesLoaded = false;

        if (options.reset) {
          this.messages = [];
          let resp = await this.$API.getMessages(room.roomId);
          messages = Model.Message.CreateList(resp);
          this.messages = messages;
        } else {
          // 加载更早的消息
          let resp = await this.$API.getMessages(room.roomId, { before: this.messages[0]._id });
          messages = Model.Message.CreateList(resp);
          this.messages = [...messages, ...this.messages];
        }
        if (messages.length < 20) {
          this.messagesLoaded = true;
        }
      },
      async sendMessage({ roomId, content }) {
        await this.$API.sendMessage(this.currentUserId, roomId, content);
      },
      async showRoomInfo(room) {
        this.$refs.chatroomSettings.Show(room.roomId);
      },
      async addRoomm() {
        this.$refs.chatroomSettings.Show();
      },
      async handleSave() {
        this.fetchRooms();
      },
      async handleSuggestionClick(suggestion) {
        this.sendMessage({
          roomId: this.currentRoomId,
          content: suggestion,
        });
        this.suggestions = [];
      },
      async roomActionHandler({ roomId, action }) {
        if (action.name == "delete") {
          var chatbotId = _.split(roomId, "_")[1];
          await this.$API.deleteChatbot(chatbotId);
          this.fetchRooms();
        }
      },
      addCustomStyle() {
        // 因为 vac 为 shadow dom，所以无法直接修改样式
        const style = document.createElement("style");
        style.textContent = "#messages-list { padding-bottom: 50px; }";
        this.$refs.vac.shadowRoot.appendChild(style);
      },
    },
    created() {
      this.ws = io(settings.WS_HOST);
      this.ws.on("connect", () => {
        console.log("connect");
        this.ws.emit("join", {
          user_id: this.currentUserId,
          room_id: this.currentRoomId,
        });
      });
      this.ws.on("disconnect", () => {
        console.log("disconnect");
      });
      this.ws.on("sync-message", (data) => {
        console.log("sync-message", data);
        let message = Model.Message.Create(data);
        this.messages = [...this.messages, message];
      });
      this.ws.on("suggestions", (suggestions) => {
        console.log("suggestions", suggestions);
        this.suggestions = suggestions;
      });
    },
    destroyed() {
      this.ws.close();
    },
    mounted() {
      this.fetchRooms();
      this.$nextTick(() => {
        this.addCustomStyle();
      });
    },
    unmounted() {},
  };
</script>

<style lang="scss">
  #suggestions {
    position: fixed;
    right: 0;
    bottom: 70px;
    width: calc(100vw - 330px);

    justify-content: center;
    display: flex;
  }
</style>
