class Room {
  /* 参考格式
  {
    roomId: "1",
    roomName: "ChatGPT3",
    avatar: "assets/imgs/people.png",
    unreadCount: 0,
    index: 3,
    lastMessage: {
      _id: "xyz",
      content: "Last message received",
      senderId: "1234",
      username: "John Doe",
      timestamp: "10:20",
      saved: true,
      distributed: false,
      seen: false,
      new: true,
    },
    users: [
      {
        _id: "1234",
        username: "John Doe",
        avatar: "assets/imgs/doe.png",
      },
      {
        _id: "4321",
        username: "John Snow",
        avatar: "assets/imgs/snow.png",
      },
    ],
    typingUsers: [],
  },
*/

  constructor() {
    this.roomId = "";
    this.roomName = "";
    this.avatar = "";
    this.unreadCount = 0;
    this.index = 0;
    this.lastMessage = undefined;
    this.users = [];
    this.typingUsers = [];
  }

  // 从后端接口转换
  static Create(chatbot) {
    let room = new Room();
    room.roomId = `chatbot_${chatbot.id}`;
    room.roomName = chatbot.name;
    room.avatar = chatbot.avatar;
    room.users = [User.CreateFromChatbot(chatbot), User.CreateFromUser(chatbot.user)];
    return room;
  }

  static CreateList(rooms) {
    var list = [];
    for (var i = 0; i < rooms.length; i++) {
      list.push(Room.Create(rooms[i]));
    }
    return list;
  }
}

class User {
  constructor() {
    this._id = "";
    this.username = "";
    this.avatar = "";
  }

  // 从后端接口转换
  static CreateFromChatbot(data) {
    let user = new User();
    user._id = "chatbot_" + data.id;
    user.username = data.name;
    user.avatar = data.avatar;
    return user;
  }
  // 从后端接口转换
  static CreateFromUser(data) {
    let user = new User();
    user._id = "user_" + data.id;
    user.username = data.name;
    user.avatar = data.avatar;
    return user;
  }
}

class Message {
  constructor() {
    this._id = "";
    this.index = 0;
    this.content = "";
    this.senderId = "";
    this.username = "";
    this.date = "";
    this.avatar = "/avatars/elon-musk.png";
    this.timestamp = "10:20";
  }

  // 从后端接口转换
  static Create(data) {
    let message = new Message();
    message._id = data.id;
    message.index = data.id;
    message.senderId = `${data.sender_role}_${data.sender_id}`;
    message.content = data.text;
    return message;
  }

  static CreateList(messages) {
    var list = [];
    for (var i = 0; i < messages.length; i++) {
      list.push(Message.Create(messages[i]));
    }
    return list;
  }
}

export default {
  Room,
  User,
  Message,
};
