import axios from "axios";

class API {
  // ============================ 应用管理  ============================

  async getRooms() {
    var resp = await axios.get("/api/v1/rooms", {
      params: {},
    });
    return resp.data.data;
  }

  async createChatbot(chatbot) {
    var resp = await axios.post("/api/v1/chatbots", chatbot);
    return resp.data.data;
  }

  async getChatbot(chatbot_id) {
    var resp = await axios.get("/api/v1/chatbots/" + chatbot_id);
    return resp.data.data;
  }

  async updateChatbot(chatbot) {
    var resp = await axios.put("/api/v1/chatbots/" + chatbot.id, chatbot);
    return resp.data.data;
  }

  async deleteChatbot(chatbot_id) {
    var resp = await axios.delete("/api/v1/chatbots/" + chatbot_id);
    return resp.data.data;
  }

  async getMessages(session_id, { before, after } = {}) {
    var resp = await axios.get("/api/v1/messages", {
      params: { session_id, before, after },
    });
    return resp.data.data;
  }

  async sendMessage(sender_id, session_id, content) {
    var resp = await axios.post("/api/v1/messages", {
      sender_id,
      session_id,
      text: content,
    });
    return resp.data.data;
  }
}

export default new API();
